import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectPersonalWebsiteV1PageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectPersonalWebsiteV1P = {
    data: GQLProjectPersonalWebsiteV1PageQuery;
};

function ProjectPersonalWebsiteV1(props: TReactFCP<TProjectPersonalWebsiteV1P>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml }: GQLProjectPersonalWebsiteV1PageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectPersonalWebsiteV1PageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                This was my first attempt at a personal website. I wanted to have a simple web presence which described
                my work, side projects I was working on, and hobbies. At the time I was using GoLang and {` `}
                <Link variant='external' to='https://jinja.palletsprojects.com/en/2.10.x/'>Jinja</Link>/Python
                heavily for my work at  <Link variant='internal' to='/career/'>Qntfy</Link>. Since Go's{` `}
                <code>html/template</code> library is similar to Jinja it seemed like a good way to learn if and how I
                could transition Python-based sites to GoLang.
            </Typography>
            <Typography paragraph>
                <Link variant='external' to='https://gohugo.io/'>Hugo</Link>, a static site generator built on top
                of <code>html/template</code> made the creation of the website really simple. I used a free template
                from the Hugo gallery, added my content, and published the site in less than three days. While it was
                great for a simple website like mine, I ultimately didn't iterate on it past the initial publication.
            </Typography>
            <Typography paragraph>
                The amount of effort it would take to fully customize pages and templates, difficulty of
                implementing dynamic content in the browser, and markdown-based content requirement made it difficult
                to justify spending the time to improve and maintain the site. I was also well into the development of
                {` `}<Link variant='internal' to='/projects/trackside/'>Trackside</Link>, a ReactJS-based progressive web
                application, and wanted to have the same wonderful client-side experience with my personal website.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectPersonalWebsiteV1P>): TReactFCR => (
    <Page component={ProjectPersonalWebsiteV1} cprops={props} />
);

export const query = graphql`
    query ProjectPersonalWebsiteV1Page {
        sitePage(internalComponentName: {eq: "ComponentProjectsPersonalWebsiteV1"}) {
            path
        }
        projectsYaml(pid: {eq: "personal-website-v1"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
